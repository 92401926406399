import React from 'react'
import styled from 'styled-components'

import { space } from '../../../constants/length'
import { H3 } from '../../../constants/newText'
import { viewport } from '../../../constants/viewport'

interface WrapperProps {
  maxWidth?: string | number
}
const ItemWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
  @media (max-width: ${viewport.tablet - 1}px) {
    margin: ${space.m}px auto;
  }
`
const Img = styled.img`
  max-width: 100%;
  margin-bottom: ${space.m}px;
`
const Title = styled.h3`
  ${H3}
`

type Props = WrapperProps & {
  imgUrl?: string
  imgAlt?: string
  title?: string
}
const GalleryItem = (props: Props) => {
  const { maxWidth, imgUrl, imgAlt, title } = props

  return (
    <ItemWrapper maxWidth={maxWidth}>
      <Img src={imgUrl} alt={imgAlt} />
      <Title>{title}</Title>
    </ItemWrapper>
  )
}

export default GalleryItem
