import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult4.jpg'
import snoreHow1 from '../../assets/static/service/snore_how1.png'
import snoreHow2 from '../../assets/static/service/snore_how2.png'
import snoreProgress1 from '../../assets/static/service/snore_progress1.png'
import snoreProgress2 from '../../assets/static/service/snore_progress2.png'
import snoreProgress3 from '../../assets/static/service/snore_progress3.png'
import snoreTableImage from '../../assets/static/service/snore_table.png'
import snoreImage from '../../assets/static/service/snore.png'
import step1Image from '../../assets/static/service/step1.svg'
import step2Image from '../../assets/static/service/step2.svg'
import step3Image from '../../assets/static/service/step3.svg'
import { GridLayout } from '../../components/Grid'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { H3 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import Collapse from './components/Collapse'
import ContentSection, { ContentTextStyle } from './components/ContentSection'
import GalleryItem from './components/GalleryItem'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const ContentText = styled.div`
  ${ContentTextStyle}
`
const IntroText = styled.div`
  padding-top: ${space.l}px;
`
const ProgressWrapper = styled.div`
  margin-bottom: ${space.l}px;
`
const KnowHowTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;

  @media (max-width: ${viewport.tablet}px) {
    margin-top: ${space.l}px;
  }
`
const HowToStartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space.xl}px;
  @media (max-width: ${viewport.tablet}px) {
    margin-top: 0;
  }
`
const ListGroup = styled.div`
  margin-top: ${space.m}px;
`
const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`
const H3Title = styled.h3`
  ${H3}
  text-align: left;
  margin-bottom: ${space.m}px;
  @media (max-width: ${viewport.tablet}px) {
    text-align: center;
  }
`
interface ImgProps {
  maxWidth?: string | number
}
const Img = styled.img<ImgProps>`
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  height: auto;
  margin: 0 auto;
`

/** 止鼾牙套 */
const Snore = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.snore' }),
      path: '/service/snore'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.snore' })}>
      <Seo
        title='呼吸道矯正止鼾牙套，3個月改善睡眠品質-日不落牙醫集團'
        metaDescription='你的枕邊人也總是鼾聲如雷？快帶他到日不落牙醫集團的台北、新竹、台中、台南診所，『呼吸道矯正止鼾牙套』透過專利的導舌裝置訓練舌根肌肉，暢通呼吸道解決打呼問題。'
      />
      <StyledContentTitle>{formatMessage({ id: 'service.snore' })}</StyledContentTitle>
      <ContentText>
        <p>
          你的枕邊人也總是鼾聲如雷？<strong>小心！他其實是中風的高危險族群</strong>。
        </p>
      </ContentText>
      <ContentSection id='patch' imageUrl={snoreImage} imagePosition='fluid' imageAlt='日不落止鼾牙套'>
        <IntroText>
          <p>打呼是睡眠呼吸中止症（OSA）的前兆，根據研究指出，OSA 若不治療，多數患者會合併高血壓、心臟衰竭的風險增大 1.4 倍。</p>
          <p>
            日不落的『呼吸道矯正止鼾牙套』適用於<strong>輕度至中度的阻塞型睡眠呼吸中止症</strong>
            、或是重度 OSA 患者但無法配戴持續性器到正壓呼吸器／陽壓呼吸器（CPAP）治療時，可透過規律性運動、並養成正常鼻呼吸法，以達到止鼾效果。
          </p>
        </IntroText>
      </ContentSection>
      <ContentSection title='止鼾牙套如何運作'>
        <GridLayout templateColumns={['100%']} rowGap={['unset', '24px']}>
          <GridLayout templateColumns={['100%', '283px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='283px' src={snoreHow1} />
            <KnowHowTextWrapper>
              <H3Title>為什麼會打呼?</H3Title>
              <p>打呼最主要的原因是軟顎或舌根向後掉，阻塞了呼吸道、讓呼吸道變狹窄，當呼吸時氣流通過、便會產生較大的震動聲，也就是我們聽到的打呼聲。</p>
            </KnowHowTextWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '283px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='283px' src={snoreHow2} />
            <KnowHowTextWrapper>
              <H3Title>日不落的止鼾牙套</H3Title>
              <p>
                『呼吸道矯正止鼾牙套』具有專利的特殊導舌裝置，透過訓練舌根肌肉，讓你的舌頭可在睡覺時能有力地頂在上顎，讓原本狹窄的呼吸道更加暢通。
                同時，透過戴著止鼾牙套並規律運動，改善口呼吸習慣、讓自己保持『鼻進鼻出』的呼吸法，加強呼吸道周圍肌肉張力，防止睡眠時出現阻塞現象，進而減少打呼的發生。
              </p>
            </KnowHowTextWrapper>
          </GridLayout>
        </GridLayout>
      </ContentSection>
      <ContentSection title='如何開始日不落止鼾牙套'>
        <GridLayout templateColumns={['100%']} rowGap={['32px', '24px']}>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step1Image} />
            <HowToStartWrapper>
              <H3Title>前往日不落旗下診所，進行諮詢</H3Title>
              <p>透過 X 光影像檢查及 3D 數位取模，綜合您的呼吸習慣及舌頭水腫情況進行資料分析，提供客製化治療方案。</p>
            </HowToStartWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step2Image} />
            <HowToStartWrapper>
              <H3Title>客製化設計與製造</H3Title>
              <p>分析臨床呼吸道數據與下巴最舒服的休憩位置（Rest position），設計客製化的呼吸道矯正止鼾牙套。</p>
              <ListItem content='嬰兒級矽膠製成，柔軟耐用' />
              <ListItem content='客製化製作，完美服貼牙齒外型' />
            </HowToStartWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step3Image} />
            <HowToStartWrapper>
              <H3Title>配戴矯正與改善追蹤</H3Title>
              <p>進行為期 3 個月的呼吸道矯正訓練，確認舌頭側邊齒痕、口呼吸情況有所改善後，再開始戴上止鼾牙套睡覺。</p>
              <ListItem content='0 配件，無拘束一夜好眠' />
              <ListItem content='軟毛牙刷清水沖洗，乾淨衛生無虞' />
            </HowToStartWrapper>
          </GridLayout>
        </GridLayout>
      </ContentSection>
      <ContentSection title='呼吸道矯正訓練'>
        <ProgressWrapper>
          <GridLayout templateColumns={['100%', 'repeat(3,1fr)']}>
            <GalleryItem maxWidth='200px' imgUrl={snoreProgress1} imgAlt='日不落牙醫集團止鼾牙套訓練1-吞嚥訓練' title='吞嚥訓練' />
            <GalleryItem maxWidth='200px' imgUrl={snoreProgress2} imgAlt='日不落牙醫集團止鼾牙套訓練2-緊咬訓練' title='緊咬訓練' />
            <GalleryItem maxWidth='200px' imgUrl={snoreProgress3} imgAlt='日不落牙醫集團止鼾牙套訓練3-運動習慣' title='運動習慣' />
          </GridLayout>
        </ProgressWrapper>
        <ListGroup>
          <ListItem content='舌頭抵入導舌板，練習吞嚥口水' />
          <ListItem content='睡前兩小時，做咬緊 10 秒再放鬆 * 150 次' />
          <ListItem content='戴上牙套，跑步 20 分鐘' />
          <ListItem content='經過 3 個月呼吸道矯正訓練後，再開始戴上止鼾牙套睡覺' />
        </ListGroup>
      </ContentSection>
      <ContentSection imageUrl={snoreTableImage} title='日不落止鼾牙套比較表' imagePosition='fluid' imageAlt='日不落止鼾牙套比較表' borderInvisible />
      <QuestionSection>
        <ContentSection title='止鼾牙套 常見問題'>
          <Collapse title='什麼樣的人適合使用止鼾牙套？'>
            <p>
              日不落的止鼾牙套適用輕度至中度的阻塞型 OSA 患者，因此在配戴止鼾牙套前，建議先進行睡眠檢測，確認自己是屬於哪種
              OSA（阻塞型？中樞型？混合型？）；或是到牙醫診所進行呼吸道 X 光檢測，以影像確認呼吸道阻塞程度。
            </p>
            <p>若您屬於重度阻塞型 OSA 且 BMI＞30 ，或是嘔吐反射過強，就不適合配戴止鼾牙套。</p>
          </Collapse>
          <Collapse title='配戴止鼾牙套會不舒服嗎？'>
            <p>初次配戴時會有異物存在的感受，可能會有一些不適或異物感，且牙齒會有被咬緊的痠痠感覺。但如果配戴時會有顳顎關節疼痛，則須重新製作、調整牙套下顎位置。</p>
          </Collapse>
          <Collapse title='配戴止鼾牙套一定要搭配訓練嗎？'>
            <p>呼吸道矯正止鼾牙套就像是健身訓練器，若沒搭配運動是不會產生顯著改善。</p>
            <p>若想要立即見效，日不落亦有提供下巴牽引式止鼾牙套，但就像是止痛藥，久了可能要加大牽引量才有同樣效果。</p>
          </Collapse>
          <Collapse title='每天要花多少時間練習止鼾運動？'>
            <p>前 3 個月的呼吸道矯正訓練，包含睡前兩小時的吞嚥訓練及緊咬訓練，並挪出額外 20 分鐘戴上牙套跑步，養成規律運動習慣。</p>
          </Collapse>
          <Collapse title='日不落的止鼾牙套與市售的止鼾器有什麼不同嗎？'>
            <p>日不落的止鼾牙套採用嬰兒級矽膠製成，依據您的齒型及下巴休憩位置（Rest position）客製而成，它們提供更高的舒適度和治療效果。</p>
            <p>市售的止鼾器多採熱塑立即裝戴型、是一種通用的選擇，使用感受可能不如客製化止鼾牙套那麼貼合，更有可能讓下巴過度牽引造成不適。</p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default Snore
